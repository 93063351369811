
import { Options, Vue } from 'vue-class-component';
// run: npm install socket.io-client --save
import io from 'socket.io-client';
import Cookies from 'js-cookie';

@Options({
  components: { },
  data() {
    return {
      cookieEnabled: false,
      test: false,
      popUpMenue: false,
      cookies: {},
    };
  },
  computed: {
    mapEnabled() {
      return this.$store.state.map.enabled;
    },
  },
  created() { // no DOM
    console.log(this.$route.path);
    console.log('Starting Connection to WebSocket Server');
    // this.$store.state.socket = io('https://socket.fth-engineering.de',
    this.$store.state.socket = io(
      'https://wegbiersocket.shardcraft.com',
      { transports: ['websocket'] },
    );
    this.cookies = Cookies.get();
    console.log('Cookies:');
    console.log(this.cookies);
    if (this.cookies.sessionId === undefined) {
      this.$store.state.socket.emit('get session id', null);
    } else {
      this.$store.state.socket.emit('set session id', this.cookies.sessionId);
      this.$store.state.user.sessionId = this.cookies.sessionId;
    }

    this.$store.state.socket.on('get session id', (data) => {
      console.log('socket.on: get session id:');
      console.log(data);
      if (data.success) {
        this.$store.state.user.sessionId = data.sessionId;
      }
    });

    // Get User Info and User Beers
    let data = {
      sessionId: this.$store.state.user.sessionId,
      userId: 0,
    };
    this.$store.state.socket.emit('get options', data);

    this.$store.state.socket.on('emit options', (dat) => {
      console.log('get options:');
      console.log(dat);
      if (dat.status === true) {
        this.$store.state.user.name = dat.result[0].name;
        this.$store.state.user.userId = dat.result[0].id;
        this.$store.state.user.email = dat.result[0].email;
        this.$store.state.user.homePos[0] = dat.result[0].x;
        this.$store.state.user.homePos[1] = dat.result[0].y;

        data = {
          sessionId: this.$store.state.user.sessionId,
          userId: this.$store.state.user.userId,
        };

        this.$store.state.socket.emit('get request message', data);
        this.$store.state.socket.emit('get answer message', data);
      }
    });

    this.$store.state.socket.on('emit request message', (res) => {
      console.log('emit request message:');
      console.log(res);

      this.$store.state.map.requests = [];
      this.addRequestMessages(res, this.$store.state.map.requests);
    });

    this.$store.state.socket.on('emit answer message', (res) => {
      console.log('emit answer message:');
      console.log(res);

      this.$store.state.map.answers = res.result;

      // this.$store.state.map.requests = [];
      // this.addRequestMessages(res, this.$store.state.map.requests);
      // this.addAnswerMessages(res, this.$store.state.map.beers);
    });

    this.$store.state.socket.on('emit beers', (res) => {
      console.log('emit beers:');
      console.log(res);
      if (res.status === true) {
        this.$store.state.user.beers = [];
        for (let i = 0; i < res.result.length; i += 1) {
          const beer = {
            id: res.result[i].id,
            wegbiername: res.result[i].name,
            wegbiertext: res.result[i].info,
            wegbierpos: [res.result[i].x, res.result[i].y],
            days: [res.result[i].mo === 1,
              res.result[i].di === 1,
              res.result[i].mi === 1,
              res.result[i].do === 1,
              res.result[i].fr === 1,
              res.result[i].sa === 1,
              res.result[i].so === 1,
            ],
            timeStart: res.result[i].timeStart,
            timeEnd: res.result[i].timeEnd,
          };
          this.$store.state.user.beers.push(beer);
        }
      }
    });

    this.$store.state.socket.on('new radar message', (dat) => {
      console.log('new radar message:');
      console.log(dat);
    });
  },
  methods: {
    // Führende nullen bei z.B. einstelligen Minuten
    padTo2Digits(num) {
      return String(num).padStart(2, '0');
    },
    displayHoursAndMinutes(timeStamp) {
      return `${timeStamp.getHours()}:${this.padTo2Digits(timeStamp.getMinutes())}`;
    },
    /*
    addAnswerMessages(res, beers) {
      for (let i = 0; i < res.result.length; i += 1) {
        const { beerId } = res.result[i];
        const message = {
          fromUserId: res.result[i].fromUserId,
          toUserId: res.result[i].toUserId,
          time: this.displayHoursAndMinutes(new Date(res.result[i].timeStamp)),
          text: res.result[i].message,
        };

        // Array Index ermitteln (schauen ob es schon ein Eintrag mit beerId gibt)
        let requestsArrayIdx = -1;
        for (let j = 0; j < beers.length; j += 1) {
          if (beers[j].id === beerId) {
            requestsArrayIdx = j;
          }
        }

        // Wenn kein passender Eintrag gefunden:
        if (requestsArrayIdx === -1) {
          requestsArrayIdx = beers.length;
        }

        if (beers[requestsArrayIdx] !== undefined) {
          // push message
          beers[requestsArrayIdx].messages.push(message);
        } else {
          if (res.result[i].fromUserId !== this.$store.state.user.userId) {
            beers.push({
              id: beerId,
              userId: this.$store.state.user.userId,
              requesterUserId: res.result[i].fromUserId,
              beerName: res.result[i].beerName,
              userName: res.result[i].fromUserName,
              messages: [],
            });
          }

          // push message
          beers[requestsArrayIdx].messages.push(message);
        }
      }
    },
    */
    addRequestMessages(res, requests) {
      for (let i = 0; i < res.result.length; i += 1) {
        const { beerId } = res.result[i];
        const message = {
          fromUserId: res.result[i].fromUserId,
          toUserId: res.result[i].toUserId,
          time: this.displayHoursAndMinutes(new Date(res.result[i].timeStamp)),
          text: res.result[i].message,
        };

        // Array Index ermitteln (schauen ob es schon ein Eintrag mit beerId gibt)
        let requestsArrayIdx = -1;
        for (let j = 0; j < requests.length; j += 1) {
          if (requests[j].beerId === beerId) {
            requestsArrayIdx = j;
          }
        }

        // Wenn kein passender Eintrag gefunden:
        if (requestsArrayIdx === -1) {
          requestsArrayIdx = requests.length;
        }

        if (requests[requestsArrayIdx] !== undefined) {
          // push message
          requests[requestsArrayIdx].messages.push(message);
        } else {
          if (res.result[i].fromUserId !== this.$store.state.user.userId) {
            requests.push({
              beerId,
              userId: this.$store.state.user.userId,
              requesterUserId: res.result[i].fromUserId,
              beerName: res.result[i].beerName,
              userName: res.result[i].fromUserName,
              messages: [],
            });
          }

          // push message
          requests[requestsArrayIdx].messages.push(message);
        }
      }
    },
  },
  mounted() { // with DOM
  },
})

export default class Home extends Vue {}
