import { createApp } from 'vue';
import OpenLayersMap from 'vue3-openlayers';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';

import './index.css';

import 'vue3-openlayers/dist/vue3-openlayers.css';

// createApp(App).use(store).use(router).mount('#app');

const app = createApp(App);
app.use(OpenLayersMap);
app.use(store);
app.use(router);

app.mount('#app');
