import { createStore } from 'vuex';

export default createStore({
  state: {
    user: {
      sessionId: 0,
      userId: 0,
      position: [0, 0],
      homePos: [0, 0],
      beers: [],
      name: '',
      email: '',
    },
    map: {
      enabled: false,
      beers: [],
      answers: [],
      requests: [{
        beerId: 1,
        userId: 1,
        beerName: 'Gampert',
        userName: 'Dursti',
        messages: [{
          fromUserId: 3,
          toUserId: 1,
          time: '12:17',
          text: 'Hi, ich bäuchte dringend ein Bier. Bist du um 17:30 Zuhause?',
        },
        {
          fromUserId: 1,
          toUserId: 3,
          time: '12:24',
          text: 'Na klar, komm gerne vorbei. Bier steht kühl 😉',
        }],
        gelesen: false,
        answer: '',
      },
      {
        beerId: 2,
        userId: 1,
        beerName: 'Recken Keller',
        userName: 'Alki',
        messages: [{
          fromUserId: 3,
          toUserId: 1,
          time: '11:02',
          text: 'Wie wäre es später mit etwas Bier? Ich komme um 18:00 vorbei, k?',
        }],
        gelesen: true,
        answer: '',
      }],
    },
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  },
});
